import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [];

export const dictionary = {
		"/(auth)": [19,[2]],
		"/(protected)/applications": [32,[3,7]],
		"/(protected)/component-view": [33,[3,8]],
		"/(protected)/component-view/[instanceId]": [34,[3,8,9]],
		"/(protected)/component-view/[instanceId]/(network-device)/add-device": [35,[3,8,9,10]],
		"/(protected)/component-view/[instanceId]/(network-device)/add-protocol-converter/[connectionId]": [36,[3,8,9,10,11]],
		"/(protected)/component-view/[instanceId]/(network-device)/edit-device/[connectionId]": [37,[3,8,9,10,12]],
		"/(protected)/component-view/[instanceId]/(network-device)/edit-protocol-converter/[protocolConverterId]": [38,[3,8,9,10,13]],
		"/(protected)/(data-flow)/data-flow": [21,[3,4]],
		"/(protected)/(data-flow)/data-flow/add-custom-component": [22,[3,4]],
		"/(protected)/(data-flow)/data-flow/edit-custom-component/[instanceId]/[componentId]": [23,[3,4]],
		"/(protected)/(instances-view)/instances-view": [24,[3,5]],
		"/(protected)/(instances-view)/instances-view/add": [25,[3,5]],
		"/(protected)/(instances-view)/instances-view/edit/[instanceId]": [26,[3,5]],
		"/(protected)/instance/add": [39,[3,14]],
		"/(protected)/(network-devices)/network-devices": [27,[3,6]],
		"/(protected)/(network-devices)/network-devices/add": [28,[3,6]],
		"/(protected)/(network-devices)/network-devices/edit/[instanceId]/[deviceId]": [29,[3,6]],
		"/(protected)/(network-devices)/network-devices/edit/[instanceId]/[deviceId]/protocol-converter/add": [30,[3,6]],
		"/(protected)/(network-devices)/network-devices/edit/[instanceId]/[deviceId]/protocol-converter/edit/[protocolConverterId]": [31,[3,6]],
		"/(protected)/network-view": [40,[3,15]],
		"/(auth)/register": [20,[2]],
		"/(protected)/settings": [41,[3,16]],
		"/(protected)/tag-browser": [42,[3,17]],
		"/(protected)/trace": [43,[3,18]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';